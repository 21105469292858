import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom';
import Content from './../../components/Content';

class Products extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const content = this.props.match.params.id 
            ? "Product detail goes here" 
            : <ul>
                <li><Link to="/products/product-name-01">Product Name 01</Link></li>
                <li><Link to="/products/product-name-02">Product Name 02</Link></li>
                <li><Link to="/products/product-name-03">Product Name 03</Link></li>
                <li><Link to="/products/product-name-04">Product Name 04</Link></li>
            </ul>;
        return <Content title={this.props.match.params.id ? this.props.match.params.id : "Products"}>
            {content}
        </Content>;
    }
}

export default withRouter(Products);