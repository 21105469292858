import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import './../../assets/styles/global.scss';

import Page from '../Page';
import Navigation from '../Navigation';

import Home     from './../../screens/Home';
import About    from './../../screens/About';
import Products from './../../screens/Products';
import Services from './../../screens/Services';
import Contact  from './../../screens/Contact';

class App extends Component {
    render() {
        return <Router>
            <Page navigation={<Navigation items={[
                <Link key="home" to="/">Home</Link>,
                <Link key="about" to="/about" >About</Link>,
                <Link key="products" to="/products">Products</Link>,
                <Link key="services" to="/services">Services</Link>,
                <Link key="contact" to="/contact">Contact</Link>
            ]} />}>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/products/:id">
                        <Products />
                    </Route>
                    <Route path="/products">
                        <Products />
                    </Route>
                    <Route path="/services">
                        <Services />
                    </Route>
                    <Route path="/contact">
                        <Contact />
                    </Route>
                    <Route path="*"><h1>Nothing found!</h1></Route>
                </Switch>
            </Page>
        </Router>;
    }
}

export default App;