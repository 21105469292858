import React from 'react';
import classes from './style.module.scss';

const Navigation = (props) => {
    return <nav className={classes.Navigation}>
        <ul>
            {props.items.map((item) => {
                return <li key={item.key}>{item}</li>
            })}
        </ul>
    </nav>;
}

export default Navigation;