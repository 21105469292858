import React, {Component} from 'react';
import Content from './../../components/Content';

class About extends Component {
    render() {
        return <Content title="About">
            Content goes here
        </Content>;
    }
}

export default About;