import React from 'react';
import classes from './style.module.scss';

const Header = (props) => {
    return <header>
        <div className={classes.Brand}>Auris Studio</div>
        {props.navigation}
    </header>;
}

export default Header;