import React from 'react';
import Header from './../Header';

const Page = (props) => {
    return <div>
        <Header navigation={props.navigation} />
        {props.children}
    </div>;
}

export default Page;